<template>
  <v-navigation-drawer
    v-model="drawer"
    fixed
    temporary
  >
    <v-list>
      <template v-for="(item, index) in menuItems">
        <!-- Menu items group -->
        <v-list-group v-if="item.children" :key="index">
          <template #activator>
            <v-list-item-content>
              <v-list-item-title v-text="item.title"></v-list-item-title>
            </v-list-item-content>
          </template>

          <template v-for="(subItem) in item.children">
            <v-list-item :key="subItem.title" :to="subItem.route" v-if="subItem.route" class="pl-10" exact>
              <v-list-item-content>
                <v-list-item-title>{{ subItem.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              :key="subItem.title"
              v-else-if="subItem.href"
              :href="subItem.href"
              class="pl-10"
              target="_blank">
              <v-list-item-content>
                <v-list-item-title>{{ subItem.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list-group>

        <!-- Menu item -->
        <v-list-item
          v-else
          :key="index"
          :to="item.route"
          exact>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: 'MenuItemsNavigationDrawer',
  props: {
    menuItems: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    drawer: false,
  }),
  created() {
    this.$eventBus.$on('setDrawer', () => {
      this.drawer = !this.drawer;
    });
  },
};
</script>

<style scoped>

</style>
