const TriangleColor = {
  INVISIBLE: 'rgba(0,0,0,0)',
  WHITE: '#ffffff',
  WARM_RED: 'rgb(255, 84, 84)',
  WARM_YELLOW: '#f5ff75',
  LIGHT_RED: '#ff7676',
  LIGHT_PINK: '#f4b7b7',
};

export { TriangleColor };
