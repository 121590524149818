<template>
  <v-sheet height="80%">
    <v-row align="center" justify="center" class="fill-height text-center">
      <v-col cols="12" sm="10">
        <VImg :src="require('../assets/images/shuttles/badminton-154883_1280.png')"
              alt="Page not found"
              contain
              width="85%"
              max-width="300px"
              class="mx-auto mb-4"/>
        <h2 class="display-1">Misgeslagen</h2>
        <span class="caption">De pagina die u probeerde te bereiken bestaat niet.</span>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
export default {
  name: 'Page404',
};
</script>

<style scoped>

</style>
