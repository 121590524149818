<template>
  <v-footer color="grey darken-3" padless>
    <v-row justify="center" no-gutters>
      <v-col cols="12" sm="10" class="pa-4 text-end">
        <!-- Facebook -->
        <v-btn fab color="accent" class="ml-1" target="_blank" href="https://www.facebook.com/abcammerzoden/">
          <v-icon>fab fa-facebook-f</v-icon>
        </v-btn>
        <!-- Maps -->
        <v-btn fab color="grey darken-1" class="ml-1" target="_blank" href="https://www.google.nl/maps/place/@51.7503288,5.2215432,19z/data=!4m5!3m4!1s0x47c6f28ef6704f65:0x7c622442cff830da!8m2!3d51.7504789!4d5.2214583">
          <v-icon color="white">fa-map-marker</v-icon>
        </v-btn>
        <!-- Phone -->
<!--    <v-btn fab color="grey darken-1" class="ml-1" target="_blank" href="">
          <v-icon color="white">fa-phone</v-icon>
        </v-btn>-->
        <!-- Mail -->
        <v-btn fab color="grey darken-1" class="ml-1" target="_blank" href="mailto:info@abc-ammerzoden.nl">
          <v-icon color="white">fa-envelope</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="12" class="text-center grey darken-4 pt-4 pb-12">
        <span class="grey--text text--darken-2">Copyright ABC Ammerzoden</span>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
export default {
  name: 'TheMainFooter',
};
</script>

<style scoped>
.facebook-button {
  background: #007bff;
}
</style>
