<template>
  <v-row no-gutters>
    <ThePageTitle title="Kalender"/>
    <WidgetTriangle on-top/>
    <TheWidget title="" no-image>
      <iframe src="https://calendar.google.com/calendar/embed?height=600&amp;wkst=2&amp;bgcolor=%23ffffff&amp;ctz=Europe%2FAmsterdam&amp;src=bzFzcW0xNm91aG9hbGo5Yzk2bzg0anFsOXNAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&amp;color=%237CB342&amp;showTitle=1&amp;showNav=1"
              style="border-width:0"
              width="800"
              height="600"></iframe>
    </TheWidget>
  </v-row>
</template>

<script>
import WidgetTriangle from '../components/WidgetTriangle.vue';
import TheWidget from '../components/TheWidget.vue';
import ThePageTitle from '../components/ThePageTitle.vue';

export default {
  name: 'Calendar',
  components: {
    WidgetTriangle,
    TheWidget,
    ThePageTitle,
  },
};
</script>

<style scoped>

</style>
