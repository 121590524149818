<template>
  <div class="triangle" :class="{'up' : onTop}">
    <svg viewBox="0 0 1280 70"
         preserveAspectRatio="none"
         role="presentation"
         aria-hidden="true"
         v-if="!reverse">
      <polygon points="1280 0 1280 70 -10 70" :fill="bottomColor"></polygon>
      <polygon points="0 0 1280 0 -1280 140" :fill="topColor"></polygon>
    </svg>
    <svg viewBox="0 0 1280 70"
         preserveAspectRatio="none"
         role="presentation"
         aria-hidden="true"
         v-else>
      <polygon points="0 0 1280 0 1280 90" :fill="topColor"></polygon>
      <polygon points="-1280 61 1500 80 0 0" :fill="bottomColor"></polygon>
    </svg>
  </div>
</template>

<script>
import { TriangleColor } from '@/application/enum/TriangleSettings.js';

export default {
  name: 'WidgetTriangle',
  props: {
    topColor: {
      type: String,
      default: TriangleColor.INVISIBLE,
    },
    bottomColor: {
      type: String,
      default: TriangleColor.WHITE,
    },
    reverse: {
      type: Boolean,
      default: false,
    },
    onTop: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
.triangle, .triangle svg {
  height: 50px;
  width:  100%;

  &.up {
    margin-top: -50px;
  }
}
</style>
