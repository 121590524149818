<template>
  <v-row no-gutters>
    <ThePageTitle title="Sponsor Fysiotherapie Uniek"/>
    <WidgetTriangle on-top/>
    <TheWidget title="Fysiotherapie Uniek"  img-url="sponsor/Logo-Fystiotherapie-Uniek-1.png-1.png">
      <p>
        Bij Fysiotherapie Uniek kun je terecht in Ammerzoden, Aalst en Zuilichem voor Fysiotherapie. Naast algemene
        fysiotherapie zijn er diverse andere specialisaties aanwezig, namelijk:
      </p>
      <ul class="mb-4">
        <li>Revalidatie na Corona</li>
        <li>COPD & Astma</li>
        <li>Sportblessures</li>
        <li>Revalidatie na operatie</li>
        <li>Valpreventie</li>
        <li>Medical taping (o.a. hooikoorts)</li>
        <li>Mulligan mobilisatietechnieken</li>
      </ul>

      <p>Daarnaast worden er ook diverse individuele- en groepstrainingen (o.a. KidsGym, Zwanger & Fit) verzorgd in de
        fitnessruimte en kun je bij ons terecht voor sportmassages. Daarnaast wordt er nauw samengewerkt met andere
        specialisten om zo je klachten te behandelen vanuit de meest geschikte aanpak en wordt er indien nodig in
        overleg met jou contact opgenomen met je huisarts/specialist.Bij Fysiotherapie Uniek wordt je ontvangen in een
        aangename huiskamersetting en sta je als patiënt 100% centraal. De focus zal dan ook niet alleen liggen op het
        verhelpen van de pijn, maar ook op het verbeteren van je functies en het voorkomen van nieuwe klachten in de
        toekomst.</p>

      <p>Elke maandagavond biedt Fysiotherapie Uniek een gratis inloopspreekuur op afspraak aan waar iedereen gebruik
        van kan maken. Mensen kunnen tussen 18.30-19.30uur langskomen voor een kosteloos consult van ca. 10-15 minuten.
        Er zal een screening en onderzoek uitgevoerd worden en na afloop ontvang je een passend advies. Als blijkt dat
        een behandeling noodzakelijk is, kan je vervolgens gegarandeerd binnen 48uur terecht voor een
        vervolgafspraak.</p>

      <p>
        Bekijk voor meer informatie de website:
        <a target="_blank" href="https://fysiotherapie-uniek.nl/">https://fysiotherapie-uniek.nl</a>
      </p>
    </TheWidget>
  </v-row>
</template>

<script>
import TheWidget from '../components/TheWidget.vue';
import WidgetTriangle from '../components/WidgetTriangle.vue';
import ThePageTitle from '../components/ThePageTitle.vue';

export default {
  name: 'Sponsor',
  components: {
    TheWidget,
    WidgetTriangle,
    ThePageTitle,
  },
};
</script>

<style scoped lang="scss">
table {
  th {
    text-align: left;
  }

  td, th {
    padding: 0 10px;
  }
}
</style>
