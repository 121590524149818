<template>
  <v-row no-gutters>
    <ThePageTitle title="Contact"/>
    <WidgetTriangle on-top/>
    <TheWidget title="Hoe kan je ons bereiken?" no-image>
      <table>
        <thead>
          <tr>
            <th>Rol</th>
            <th>Naam</th>
            <th>Emailadres</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Voorzitter</td>
            <td>Danny Baumgarten</td>
            <td>voorzitter@abc-ammerzoden.nl</td>
          </tr>
          <tr>
            <td>Secretaris</td>
            <td>René van Wolferen</td>
            <td>secretaris@abc-ammerzoden.nl</td>
          </tr>
          <tr>
            <td>Penningmeester</td>
            <td>Marleen Muller</td>
            <td>penningmeester@abc-ammerzoden.nl</td>
          </tr>
          <tr>
            <td>Ledenadministratie</td>
            <td>Hanneke Luites-Durksz</td>
            <td>ledenadministratie@abc-ammerzoden.nl</td>
          </tr>
          <tr>
            <td>TC</td>
            <td>Jurian Verlouw</td>
            <td>tc@abc-ammerzoden.nl</td>
          </tr>
        </tbody>
      </table>

      <br/>
      <p>
        Hier vind u de
        <a target="_blank" href="assets/files/Privacyverklaring.pdf">Privacyverklaring</a>,
        <a target="_blank" href="assets/files/huishoudelijk_reglement_abc.pdf">Huishoudelijk reglement</a> en
        <a target="_blank" href="assets/files/statuten_abc.pdf">Statuten ABC</a>
      </p>
    </TheWidget>
    <TheWidget title="Locatie" no-image>
      <p>Sporthal de Treffer<br/>
        Het Bussche Kempke 4, 5324 AC Ammerzoden</p>
    </TheWidget>
  </v-row>
</template>

<script>
import TheWidget from '../components/TheWidget.vue';
import WidgetTriangle from '../components/WidgetTriangle.vue';
import ThePageTitle from '../components/ThePageTitle.vue';

export default {
  name: 'Contact',
  components: {
    TheWidget,
    WidgetTriangle,
    ThePageTitle,
  },
};
</script>

<style scoped lang="scss">
table {
  th {
    text-align: left;
  }

  td, th {
    padding: 0 10px;
  }
}
</style>
