<template>
  <v-app>
    <v-row justify="center" align="stretch" no-gutters>
      <MainAppBar/>

      <v-col cols="12" class="full-height content">
        <router-view></router-view>
        <TheMainFooter/>
      </v-col>
    </v-row>
  </v-app>
</template>

<script>
import TheMainFooter from './components/TheMainFooter.vue';
import MainAppBar from './components/navigation/TheMainAppBar.vue';

export default {
  name: 'App',
  components: {
    TheMainFooter,
    MainAppBar,
  },
  data: () => ({
    //
  }),
};
</script>

<style scoped>
.content {
  margin-top: 90px
}
</style>

<style>
/* TODO: place in sass file */
@font-face {
  font-family: Quicksand-Bold;
  src:         url(./assets/font/Quicksand-Bold.ttf);
}

@font-face {
  font-family: Quicksand-Light;
  src:         url(./assets/font/Quicksand-Light.ttf);
}

@font-face {
  font-family: Quicksand-Regular;
  src:         url(./assets/font/Quicksand-Regular.ttf);
}

h1, h2, h3, h4, h5, h6, p, a, div, label {
  font-family: Quicksand-Regular;
  color:       black;
}

a, a:hover {
  text-decoration: none;
}
</style>
