<template>
  <div>
    <template v-for="(item, index) in menuItems">
      <v-menu v-if="item.children" :key="index" bottom elevation="0">
        <template #activator="{ on }">
          <span class="align-self-center menu-item"
                v-on="on">
            {{item.title}}<v-icon right size="25">mdi-menu-down</v-icon>
          </span>
        </template>

        <v-list elevation="0" outlined>
          <template v-for="(subItem, index) in item.children">
            <v-list-item
              v-if="subItem.route"
              :key="index + subItem.title"
              :to="subItem.route"
              v-html="subItem.title"/>
            <v-list-item
              v-else-if="subItem.href"
              :key="index + subItem.title"
              :href="subItem.href"
              target="_blank"
              v-html="subItem.title"/>
            <VDivider v-else :key="index + subItem.title"/>
          </template>
        </v-list>
      </v-menu>
      <router-link class="menu-item px-4" :key="index" :to="item.route" v-else>
        {{item.title}}
      </router-link>
    </template>
  </div>
</template>

<script>
export default {
  name: 'MenuItemsAppBar',
  props: {
    menuItems: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped>
.menu-item {
  color:           white;
  text-decoration: none;
  font-size:       20px;
  cursor:          pointer;
}
</style>
