<template>
  <v-row no-gutters>
    <ThePageTitle title="Gang van zaken"/>
    <WidgetTriangle on-top/>
    <TheWidget title="Speeldagen" img-url="ammerzoden/Gymzaal.jpeg" reverse>
      <p>Seniorenleden kunnen op maandag- en donderdagavond terecht om een shuttletje te slaan. Op maandagavond is dit
        van 20.00 tot 22.00 uur; op donderdagavond van 20.30 tot 23.00 uur. Men mag op meerdere avonden badmintonnen.
        Thuiscompetitie voor de senioren wordt op maandagavond gespeeld.</p>
      <p>Jeugdleden kunnen op donderdagavond van 19.00 tot 20.30 uur spelen. De jeugdleden in de leeftijd van 8 tot 18
        jaar spelen allen tegelijk op de donderdagavond. Thuiscompetitie voor de jeugd wordt eveneens op donderdagavond
        gespeeld.</p>
      <p>Ongeveer één keer per maand wordt er op zondagochtend een Instuif georganiseerd. Iedereen, lid of geen lid,
        jeugd of senior, kan tegen een kleine vergoeding tussen 11.00 en 13.00 uur spelen. De kleine vergoeding kan
        gedeponeerd worden in de daarvoor bestemde bus. Neem al je familieleden, vrienden en collega’s dus mee om ze
        kennis te laten maken met de leuke badmintonsport!</p>
    </TheWidget>
    <WidgetTriangle :top-color="triangleColor.WHITE" :bottom-color="triangleColor.LIGHT_PINK" reverse/>
    <TheWidget title="Trainingen" :color="triangleColor.LIGHT_PINK">
      <p>Voor senioren, zowel beginnende als gevorderde spelers, wordt op donderdagavond training gegeven. Tijdens het
        training geven wordt er rekening gehouden met het niveau van de speler. Wij vinden het belangrijk dat iedereen
        op zijn of haar niveau kan spelen en trainen. Voor ieder lid moet er voldoende uitdaging in de training en het
        spel liggen.</p>
      <p>Voor de jeugd wordt er elke week training gegeven. Op donderdagavond zijn er altijd twee jeugdbegeleiders
        aanwezig die de jeugdleden spelregels, slagtechnieken en sportiviteit op de baan leren.</p>
    </TheWidget>
    <WidgetTriangle :top-color="triangleColor.LIGHT_PINK" :bottom-color="triangleColor.LIGHT_RED"/>
    <TheWidget title="Shuttles"
               :color="triangleColor.LIGHT_RED"
               img-url="shuttles/badminton-2646924_960_720.jpg"
               reverse>
      <p>Shuttles worden tijdens speel- en trainingsavonden en competitiewedstrijden verstrekt door de vereniging.</p>
    </TheWidget>
  </v-row>
</template>

<script>
import TheWidget from '../components/TheWidget.vue';
import WidgetTriangle from '../components/WidgetTriangle.vue';
import ThePageTitle from '../components/ThePageTitle.vue';
import { TriangleColor } from '@/application/enum/TriangleSettings.js';

export default {
  name: 'About',
  components: {
    TheWidget,
    WidgetTriangle,
    ThePageTitle,
  },
  computed: {
    triangleColor: () => TriangleColor,
  },
};
</script>

<style scoped>

</style>
