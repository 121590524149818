<template>
  <v-row class="page-title py-9" align="center" justify="center" no-gutters>
    <v-col cols="10" sm="8" class="mb-12">
      <h1 v-if="title" class="display-2 font-weight-light">{{title}}</h1>
      <slot name="default" v-else/>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'ThePageTitle',
  props: {
    title: {
      type: String,
      required: false,
    },
  },
};
</script>

<style scoped>
.page-title {
  background: linear-gradient(135deg, rgb(255, 0, 0) 0%, rgb(255, 118, 118) 100%);
}
</style>