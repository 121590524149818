<template>
  <v-row no-gutters justify="center">
    <ThePageTitle>
      <v-row no-gutters>
        <v-col cols="12" md="8">
          <ul class="display-2 font-weight-light main-title">
            <li>Ammerzodense</li>
            <li>Badminton</li>
            <li>Club</li>
          </ul>
        </v-col>
        <v-col md="4" v-if="$vuetify.breakpoint.mdAndUp">
          <VImg :src="require('../assets/images/shuttles/shuttle.png')" alt="shuttle" height="100%" contain/>
        </v-col>
      </v-row>
    </ThePageTitle>
    <WidgetTriangle on-top/>
    <TheWidget title="Eerste kennismaking met ABC" img-url="ammerzoden/bar.jpg">
      <p>De Ammerzodense Badminton Club is opgericht op 7 december 1978 en is een vereniging die zich inzet voor
        plezier, uitdaging en gezelligheid voor jong en oud.</p>
      <p>We hebben ongeveer 45 leden bestaande uit jeugdleden en seniorenleden. ABC is aangesloten bij de Bossche
        Badminton Federatie (BBF). We spelen twee keer per week, op maandag- en donderdagavond, in sporthal De Treffer
        in Ammerzoden. Dit is onze thuisbasis.</p>
      <p>ABC is een vereniging die nieuwe leden graag snel opneemt, zodat zij plezier kunnen beleven in de
        badmintonsport en zich thuis kunnen voelen binnen onze vereniging. Daarom vinden wij dat nieuwe leden extra
        aandacht verdienen.</p>
    </TheWidget>
    <WidgetTriangle :bottom-color="triangleColor.LIGHT_RED"/>
    <TheWidget title="Doelstelling ABC" :color="triangleColor.LIGHT_RED">
      <p>De doelstelling van de vereniging is het stimuleren van de badmintonsport in de ruimste zin des woords. In de
        praktijk is de recreatieve beleving van het badminton belangrijker dan de prestatie die wordt neergezet. Ter
        inzage: statuten en huishoudelijk reglement.</p>
    </TheWidget>
    <WidgetTriangle :top-color="triangleColor.LIGHT_RED" :bottom-color="triangleColor.LIGHT_PINK" reverse/>
    <TheWidget title="Activiteiten" :color="triangleColor.LIGHT_PINK" img-url="theme/photo-1521537634581-0dced2fee2ef.jpg">
      <p>Naast onze vaste speelavonden organiseren we als club ook allerlei extra activiteiten, zoals twee keer per jaar
        een toernooi voor de jeugd- en seniorenleden en kan er regelmatig ook op zondag in de ochtend worden gespeeld
        tijdens onze zogenaamde Instuif. Daarnaast organiseren we onder andere een Oud- en nieuwavond en een
        seizoensafsluiting met BBQ.</p>
    </TheWidget>
  </v-row>
</template>

<script>
import TheWidget from '../components/TheWidget.vue';
import WidgetTriangle from '../components/WidgetTriangle.vue';
import ThePageTitle from '../components/ThePageTitle.vue';
import { TriangleColor } from '../application/enum/TriangleSettings.js';

export default {
  name: 'Home',
  components: {
    TheWidget,
    WidgetTriangle,
    ThePageTitle,
  },
  computed: {
    triangleColor: () => TriangleColor,
  },
};
</script>

<style scoped lang="scss">
.main-title > li {
  display:     block;
  line-height: 100px;

  &:first-letter {
    color: white;
  }
}
</style>
