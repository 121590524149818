<template>
  <div>
    <v-app-bar color="grey darken-3" fixed dark app height="90">
      <v-avatar tile class="mr-5" size="75">
        <VImg :src="require('@/assets/logo.jpg')" contain/>
      </v-avatar>

      <MenuItemsAppBar :menu-items="menuItems" v-if="$vuetify.breakpoint.mdAndUp"/>
      <template v-else>
        <VSpacer/>
        <v-app-bar-nav-icon @click="handleDrawer"/>
      </template>
    </v-app-bar>

    <!-- Navigation on Mobile display -->
    <MenuItemsNavigationDrawer :menu-items="menuItems"/>
  </div>
</template>

<script>
import MenuItemsNavigationDrawer from './MenuItemsNavigationDrawer.vue';
import MenuItemsAppBar from './MenuItemsAppBar.vue';
import MenuItems from '@/router/menu.js';

export default {
  name: 'MainAppBar',
  components: { MenuItemsNavigationDrawer, MenuItemsAppBar },
  computed: {
    menuItems: () => MenuItems,
  },
  methods: {
    handleDrawer() {
      this.$eventBus.$emit('setDrawer', true);
    },
  },
};
</script>

<style scoped>

</style>
