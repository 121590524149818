<template>
  <v-row no-gutters>
    <ThePageTitle title="Competitie"/>
    <WidgetTriangle on-top/>
    <TheWidget title="Teams" img-url="theme/bbf.png">
      <p>Bij ABC wordt competitie gespeeld binnen de BBF (Bossche Badminton Federatie). Wil je alles weten over de
        competities van de BBF, dan kun je naar de site van de BBF gaan. Daar is echt alles te vinden op
        competitiegebied:
        <a target="_blank" href="http://www.bbfbadminton.nl/">bbfbadminton</a>
      </p>
      <p>De doelstelling van de vereniging is het stimuleren van de badmintonsport in de ruimste zin des woords. In de
        praktijk is de recreatieve beleving van het badminton belangrijker dan de prestatie die wordt neergezet.
        Trainingsfaciliteiten zijn er zowel voor de jeugd alsmede voor de senioren.</p>
    </TheWidget>

    <!-- Team 1 -->
    <WidgetTriangle :bottom-color="triangleColor.LIGHT_PINK"/>
    <TheWidget title="Team 1 - Hoofdklasse"
               :color="triangleColor.LIGHT_PINK"
               reverse
               large-image>
      <ul>
        <li>Wendy</li>
        <li>Femke</li>
        <li>Rudo</li>
        <li>Johan</li>
        <li>Jurian <span class="team-captain">Teamleider</span></li>
      </ul>
    </TheWidget>
    <WidgetTriangle :top-color="triangleColor.LIGHT_PINK" :bottom-color="triangleColor.LIGHT_RED"/>
    <TheWidget title="Team 2 - Hoofdklasse"
               :color="triangleColor.LIGHT_RED"
               reverse
               large-image>
      <ul>
        <li>Hanneke <span class="team-captain">Teamleider</span></li>
        <li>Marleen</li>
        <li>Young-Hoo</li>
        <li>Matthijs</li>
        <li>Wout</li>
      </ul>
    </TheWidget>
    <WidgetTriangle :top-color="triangleColor.LIGHT_RED" :bottom-color="triangleColor.LIGHT_PINK"/>
    <TheWidget title="Team 3 - 3e klasse"
               :color="triangleColor.LIGHT_PINK"
               reverse
               large-image>
      <ul>
        <li>Marjolein</li>
        <li>Judith</li>
        <li>Nip <span class="team-captain">Teamleider</span></li>
        <li>Antoine</li>
        <li>Kristian</li>
        <li>Ron</li>
      </ul>
    </TheWidget>
  </v-row>
</template>

<script>
import TheWidget from '../components/TheWidget.vue';
import WidgetTriangle from '../components/WidgetTriangle.vue';
import ThePageTitle from '../components/ThePageTitle.vue';
import {TriangleColor} from '../application/enum/TriangleSettings.js';

export default {
  name: 'Contact',
  components: {
    TheWidget,
    WidgetTriangle,
    ThePageTitle,
  },
  computed: {
    triangleColor: () => TriangleColor,
  },
};
</script>

<style scoped lang="scss">
.team-captain {
  color: blue;
}
</style>
