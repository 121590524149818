import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: require('../views/Home.vue').default,
    },
    {
      path: '/startpagina',
      redirect: { name: 'home' },
    },
    {
      path: '/gang-van-zaken',
      name: 'about',
      component: require('../views/About.vue').default,
    },
    {
      path: '/lidmaatschap',
      name: 'membership',
      component: require('../views/Membership.vue').default,
    },
    {
      path: '/kalender',
      name: 'calendar',
      component: require('../views/Calendar.vue').default,
    },
    {
      path: '/contact',
      name: 'contact',
      component: require('../views/Contact.vue').default,
    },
    {
      path: '/competitie',
      name: 'competition.information',
      component: require('../views/Competition.vue').default,
    },
    {
      path: '/sponsor',
      name: 'sponsor',
      component: require('../views/Sponsor.vue').default,
    },
    {
      path: '/proefles',
      name: 'lesson',
      component: require('../views/AcquaintanceLesson.vue').default,
    },
    {
      path: '/*',
      name: '404',
      component: require('../views/Page404.vue').default,
    },
  ],
});

export { router };
