<template>
  <v-card :color="color" tile elevation="0" width="100%">
    <v-row justify="center" no-gutters class="mt-2">
      <v-col cols="11" :sm="imageSizeSm" :md="imageSizeMd" v-if="reverse && !noImage">
        <VImg :src="require(`../assets/images/${imgUrl}`)" v-if="imgUrl"/>
      </v-col>
      <v-col cols="11" :sm="noImage ? 10 : textSizeSm" :md="noImage ? 10 : textSizeMd" class="py-2 px-4">
        <h2 class="display-1 font-weight-light" v-html="title"/>
        <slot name="default"/>
      </v-col>
      <v-col cols="11" :sm="imageSizeSm" :md="imageSizeMd" v-if="!reverse && !noImage">
        <VImg :src="require(`../assets/images/${imgUrl}`)" v-if="imgUrl"/>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import {TriangleColor} from '@/application/enum/TriangleSettings.js';

export default {
  name: 'TheWidget',
  props: {
    title: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      default: TriangleColor.WHITE,
    },
    reverse: {
      type: Boolean,
      default: false,
    },
    imgUrl: {
      type: String,
      default: '',
    },
    noImage: {
      type: Boolean,
      default: false,
    },
    largeImage: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    imageSizeSm: 4,
    imageSizeMd: 2,
    textSizeSm:  5,
    textSizeMd:  8,
  }),
  created() {
    if (this.largeImage) {
      this.imageSizeSm = this.largeImage ? 6 : 4;
      this.imageSizeMd = this.largeImage ? 4 : 2;
      this.textSizeSm = this.largeImage ? 6 : 5;
      this.textSizeMd = this.largeImage ? 7 : 8;
    }
  },
};
</script>

<style scoped>

</style>
