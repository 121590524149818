<template>
  <v-row no-gutters>
    <ThePageTitle title="Proefles"/>
    <WidgetTriangle on-top/>
    <TheWidget title="" no-image>
      <div style="height: 55vh">
        <p>Meld je aan via <a href="mailto:proefles@abc-ammerzoden.nl">proefles@abc-ammerzoden.nl</a></p>
      </div>
    </TheWidget>
  </v-row>
</template>

<script>
import WidgetTriangle from '../components/WidgetTriangle.vue';
import TheWidget from '../components/TheWidget.vue';
import ThePageTitle from '../components/ThePageTitle.vue';

export default {
  name: 'AcquaintanceLesson',
  components: {
    WidgetTriangle,
    TheWidget,
    ThePageTitle,
  },
};
</script>

<style scoped>

</style>
