<template>
  <v-row no-gutters>
    <ThePageTitle title="Lidmaatschap"/>
    <WidgetTriangle on-top/>
    <TheWidget title="Proefperiode" img-url="theme/badminton-1019110_960_720.jpg" reverse>
      <p>Ieder aanstaand lid kan drie keer gratis meespelen om de sfeer te proeven en kennis te maken met de
        badmintonsport en het spelniveau binnen onze vereniging. Daarna besluit je of je wel of niet lid wilt worden van
        ABC en ontvang je een inschrijfformulier en een machtigingsformulier. Wanneer je je inschrijft, kun je zowel op
        maandag- als donderdagavond spelen (jeugdleden alleen donderdagavond).</p>
      <p>Neem sportkleding, sportschoenen en een racket mee als je komt kijken. Dan kun je meteen spelen samen met onze
        leden. Als je geen racket hebt, kun je dit kosteloos lenen op een proefavond.</p>
    </TheWidget>
    <WidgetTriangle :bottom-color="triangleColor.LIGHT_RED"/>
    <TheWidget title="Contributie" :color="triangleColor.LIGHT_RED">
      <p>De contributie voor de senioren bedraagt €33,00 per kwartaal en voor de jeugdleden is dit €28,50 per kwartaal.
        Dit bedrag moet altijd vooruit betaald worden. Middels een automatische incasso wordt de contributie door onze
        penningmeester per kwartaal van je bankrekening afgeschreven.</p>
      <p>Het lidmaatschap kan per kwartaal opgezegd worden, teveel betaalde contributie wordt gezien als een donatie aan
        de vereniging. Voor het spelen van competitie bij zowel de senioren als de jeugd dient een extra
        competitiebijdrage te worden voldaan van €20,00 per jaar.</p>
    </TheWidget>
    <WidgetTriangle :top-color="triangleColor.LIGHT_RED" :bottom-color="triangleColor.LIGHT_PINK"/>
    <TheWidget title="Lid worden?" :color="triangleColor.LIGHT_PINK" no-image>
      <p>Wanneer je na 3 keer meespelen lid wilt worden, ontvang je een inschrijfformulier. Het inschrijfgeld voor
        jeugdleden bedraagt eenmalig €3,00 voor senioren is dit €4,00.</p>
      <br/>
      <p>Hier vind jij het
        <a target="_blank" href="assets/files/Inschrijfformulier-ABC.pdf">inschrijfformulier</a>
      </p>
      <br/>
      <p>Hier vind jij het
        <a target="_blank" href="assets/files/Afmeldformulier-ABC.pdf">afmeldformulier</a>
      </p>
    </TheWidget>
    <WidgetTriangle :top-color="triangleColor.LIGHT_PINK" reverse/>
    <TheWidget title="Clubkleding" :color="triangleColor.WHITE" img-url="ammerzoden/abc-folk.jpg" reverse>
      <p>Elke twee of drie jaar wordt er een nieuwe lijn shirts uitgezocht en aangeboden.</p>
      <p>Sportschoenen met zwarte zolen en sportschoenen die buiten zijn gedragen zijn verboden, uit oogpunt van hygiëne
        en om beschadigingen van de sportvloer te voorkomen. </p>
    </TheWidget>
  </v-row>
</template>

<script>
import TheWidget from '../components/TheWidget.vue';
import WidgetTriangle from '../components/WidgetTriangle.vue';
import ThePageTitle from '../components/ThePageTitle.vue';
import { TriangleColor } from '@/application/enum/TriangleSettings.js';

export default {
  name: 'Membership',
  components: {
    TheWidget,
    WidgetTriangle,
    ThePageTitle,
  },
  computed: {
    triangleColor: () => TriangleColor,
  },
};
</script>

<style scoped>

</style>
